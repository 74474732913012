(function () {
  $('input[name="type"]').on("change", function () {
    var filterType = $(this).val(); // Get selected filter type

    $.ajax({
      url: _wpObject.ajaxUrl,
      type: "POST",
      data: {
        action: "filter_and_paginate",
        filter_type: filterType, 
        paged: 1,
      },
      beforeSend: function () {
        $(".news-container").html("<p>Loading...</p>"); 
      },
      success: function (response) {
        $(".news-container").html(response); 
      },
      error: function (xhr, status, error) {
        $(".news-container").html(
          "<p>Something went wrong. Please try again.</p>"
        ); // Error handling
      },
    });
  });
})();
