(function () {
  $('.news-slider').slick({
    variableWidth: true,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 3,
          variableWidth: false
        }
      },
    ]
  });
  $('.activity-slider').slick({
    variableWidth: true,
    slidesToScroll: 1,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          variableWidth: false,
          arrows: true,
        }
      },
    ]
  });
})()