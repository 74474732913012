(function () {
  $(document).on("click", ".ajax-pagination", function (e) {
    e.preventDefault();

    var page = $(this).data("page");
    var filterType = $('input[name="type"]:checked').val();

    var searchForm = $('.search-page-form');
    var isSearch = searchForm.length > 0 ? true : false;
    var searchQuery = isSearch ? searchForm.find('input[name="s"]').val() : '';
    console.log($(searchQuery));

    $.ajax({
      url: _wpObject.ajaxUrl,
      type: "POST",
      data: {
        action: "filter_and_paginate",
        filter_type: filterType,
        paged: page,
        is_search: isSearch,
        s: searchQuery,
      },
      beforeSend: function () {
        $("#ajax-pagination-container").html("<p>Loading...</p>");
      },
      success: function (response) {
        $("#ajax-pagination-container").html(response);
      },
      error: function (xhr, status, error) {
        $("#ajax-pagination-container").html(
          "<p>Something went wrong. Please try again.</p>"
        );
      },
    });
  });
})();
