(function () {
  const googleMapEle = document.getElementById('bins-map');

  if (!googleMapEle) return;

  const filterElems = document.querySelectorAll('.map-filter');

  const locations = document.querySelectorAll('.bin-location__address')

  const geocoder = new google.maps.Geocoder();

  let map

  let centers = {}

  filterElems.forEach(filter => {
    const name = filter.getAttribute('data-type')
    if (name !== 'all') {
      centers[name] = {
        lat: Number(filter.getAttribute('data-lat')),
        lng: Number(filter.getAttribute('data-lng'))
      }
    }
  })

  async function initMap() {

    const {Map} = await google.maps.importLibrary("maps");
    const {AdvancedMarkerElement} = await google.maps.importLibrary("marker");

    map = new Map(googleMapEle, {
      center: {lat: 39.3069966, lng: -119.667146},
      zoom: 9,
      mapId: "4504f8b37365c3d0",
    });

    locations.forEach(location => {
      const address = location.innerHTML.replaceAll('<br>', ' ')
      const type = location.getAttribute('data-type')
      geocoder.geocode({'address': address}, function (results, status) {

        if (status == 'OK') {
          const markerEl = document.createElement("div");

          markerEl.className = 'location-marker';
          markerEl.setAttribute('data-type', type)
          markerEl.textContent = "$2.5M";

          var marker = new AdvancedMarkerElement({
            map: map,
            position: results[0].geometry.location,
            title: type,
            // content: markerEl
          });
        } else {
          alert('Geocode was not successful for the following reason: ' + status + 'address' + address);
        }
      });
    })

  }


  initMap();

  filterElems.forEach(filter => {
    filter.addEventListener('click', function (e) {
      e.preventDefault();
      const type = filter.getAttribute('data-type');
      const markers = document.querySelectorAll('.yNHHyP-marker-view');
      if (type == "all") {
        map.setZoom(9)
        markers.forEach(marker => marker.classList.remove('hidden'))
      } else {
        markers.forEach(marker => {
          map.setZoom(11)
          map.setCenter(centers[type]);
          if (marker.getAttribute('title') === type) {
            marker.classList.remove('hidden')
          } else {
            marker.classList.add('hidden')
          }
        })
      }
    })
  })
})
();
