(function () {
  $('#event-start-date').daterangepicker({
    minDate: new Date(),
    autoUpdateInput: false,
    locale: {
      format: 'MMMM DD'
    }
  });

  $('.event-calendar-form__dates').on('click', function () {
    $('#event-start-date').trigger('click')
  });

  $('#event-start-date').on('hide.daterangepicker', function(ev, picker) {
    $('#event-start-date').val(picker.startDate.format('MM-DD-YY'));
    $('#event-end-date').val(picker.endDate.format('MM-DD-YY'));
  });

})();