(function () {

  const $header = document.querySelector('.header');
  const $menu = document.querySelector('.main-nav');
  const $hamburger = document.querySelector('.burger');
  const $search = document.querySelector('.header-search');
  const $searchOpener = $search.querySelector('.header-search__opener');
  const $searchClose = $search.querySelector('.header-search__form-close');
  let isMenuOpened = false;

  $searchOpener.addEventListener('click', function () {
    $search.classList.add('opened')
  })

  $searchClose.addEventListener('click', function () {
    $search.classList.remove('opened');
  })

  let itemsWithChildren = document.querySelectorAll('.main-nav ul > .menu-item-has-children');
  let firstLevelItemsWithChildrenLink = document.querySelectorAll('.main-nav .menu > .menu-item-has-children > a');
  let firstLevelItemsWithChildren = document.querySelectorAll('.main-nav .menu > .menu-item-has-children');
  let secondLevelItemsWithChildren = document.querySelectorAll('.main-nav .submenu > .menu-item-has-children > a');

  if (firstLevelItemsWithChildrenLink) {
    firstLevelItemsWithChildrenLink.forEach(item => {
      if (window.innerWidth <= 992) {
        item.addEventListener('click', function (e) {
          e.preventDefault();
          this.closest('.menu-item-has-children').classList.add('submenu-opened')
        })
      }
    })
  }
  if (firstLevelItemsWithChildren) {
    firstLevelItemsWithChildren.forEach(item => {
      if (window.innerWidth > 992) {
        item.addEventListener('mouseenter', function () {
          this.closest('.menu-item-has-children').classList.add('submenu-opened')
        })
        item.addEventListener('mouseleave', function () {
          this.closest('.menu-item-has-children').classList.remove('submenu-opened')
          this.querySelector('.submenu').classList.remove('submenu-opened')
        })
      }
    })
  }

  if (secondLevelItemsWithChildren) {
    secondLevelItemsWithChildren.forEach(item => {
      item.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.closest('.submenu').classList.add('submenu-opened');
      })
    })
  }


  for (let item of itemsWithChildren) {
    let title = item.children[0].textContent;
    let linkHref = item.children[0].getAttribute('href')
    let node = document.createElement('li');
    let link = document.createElement('a');
    link.textContent = title;
    link.setAttribute('href', linkHref)
    node.classList.add('submenu__title');
    item.querySelector('.submenu').prepend(node)
    node.prepend(link)
    node.addEventListener('click', function () {
      this.closest('.submenu-opened').classList.remove('submenu-opened')
    })
  }

  $hamburger.addEventListener('click', function () {
    this.classList.toggle('opened')
    $header.classList.toggle('menu-opened');
    isMenuOpened = !isMenuOpened;

    if (isMenuOpened) {
      bodyScrollLock.disableBodyScroll($menu);
    } else {
      bodyScrollLock.clearAllBodyScrollLocks();
      const openedSubmenu = $menu.querySelectorAll('.submenu-opened');
      openedSubmenu.forEach(item => item.classList.remove('submenu-opened'))
    }
  });

  window.addEventListener('resize', debounce(function () {
    if (isMenuOpened && window.innerWidth >= 768) {
      bodyScrollLock.clearAllBodyScrollLocks();
    }
  }))

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
})();
